import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import Title from '../../components/Title';
import FirstAccessLayout from '../../layouts/FirstAccessLayout';
import Stepper from '../../components/Stepper';
import Select from '../../components/Select';
import { addressMaxLength, UFs } from '../../constants/general';
import { IMaskInput } from 'react-imask';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Company } from '../../types/company';
import { Semibold } from '../../components/Typography';

const FIELD_REQUIRED_MESSAGE = 'Você precisa preencher este campo.';

const CompanyRegistrationPage = () => {
  const [addressWithoutNumber, setAddressWithoutNumber] = useState(false);
  const [addressWithoutComplement, setAddressWithoutComplement] =
    useState(false);

  const navigate = useNavigate();

  const { selectedCompany, setSelectedCompany } = useUserCompaniesStore();

  const selectedCompanyAddress = selectedCompany?.addresses?.[0];

  const schema = z.object({
    companyId: z.string().nullish(),
    addressId: z.string().nullish(),
    customerId: z.string().nullish(),
    company: z.object({
      name: z.string().min(1, FIELD_REQUIRED_MESSAGE),
      tradeName: z.string(),
    }),
    address: z.object({
      address: z.string().min(1, FIELD_REQUIRED_MESSAGE),
      complement: z.preprocess((val) => {
        if (typeof val === 'string') {
          return val.trim();
        }
      }, z.string().nullish()),
      number: z.preprocess((val) => {
        if (typeof val === 'string') {
          return val.trim();
        }
      }, z.string().nullish()),
      district: z.string().min(1, FIELD_REQUIRED_MESSAGE),
      city: z.string().min(1, FIELD_REQUIRED_MESSAGE),
      state: z
        .string({ required_error: 'Você precisa selecionar um estado.' })
        .min(1, FIELD_REQUIRED_MESSAGE),
      zipCode: z
        .string({ required_error: 'CEP inválido' })
        .min(1, FIELD_REQUIRED_MESSAGE),
    }),
  });

  type FormValues = z.infer<typeof schema>;

  const { handleSubmit, register, control, setValue, clearErrors, formState } =
    useForm<FormValues>({
      resolver: zodResolver(schema),
      defaultValues: {
        addressId: selectedCompanyAddress?.id,
        companyId: selectedCompany?.id,
        customerId: selectedCompany?.customerId,
        company: {
          name: selectedCompany?.name,
          tradeName: selectedCompany?.tradeName,
        },
        address: {
          address: selectedCompanyAddress?.address,
          complement: selectedCompanyAddress?.complement,
          number: selectedCompanyAddress?.number,
          district: selectedCompanyAddress?.district,
          city: selectedCompanyAddress?.city,
          state: selectedCompanyAddress?.state,
          zipCode: selectedCompanyAddress?.zipCode,
        },
      },
    });

  const { errors } = formState;

  const { api } = useFetchContext();

  const toast = useToast();

  const queryClient = useQueryClient();

  const updateCompanyInfo = useMutation({
    mutationFn: (values: FormValues) =>
      api.patch<Company>('/api/customers/setup/step-two', values),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['user-companies'] });
      const company = response.data;
      setSelectedCompany(response.data);
      toast({
        title: 'Dados da empresa atualizados com sucesso',
        status: 'success',
      });
      if (company.registrationStep === 3) {
        navigate('/termos-de-uso');
      }
    },
    onError: () => {
      toast({
        title: 'Erro ao atualizar dados da empresa',
        description: 'Tente novamente mais tarde',
        status: 'error',
      });
    },
  });

  function onSubmit(data: FormValues) {
    updateCompanyInfo.mutate(data);
  }

  return (
    <FirstAccessLayout>
      <Box>
        <Card bg="#fff" maxWidth="900px" boxShadow="none">
          <CardHeader>
            <Flex direction="column" gap={4}>
              <Stepper numberOfSteps={4} activeStep={2} />
              <Title>Confirme os dados da empresa</Title>
            </Flex>
          </CardHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody paddingTop={0}>
              <Flex direction="column" gap={6}>
                <Box>
                  <Text fontWeight={600} marginBottom={4}>
                    Informações principais
                  </Text>

                  <Grid
                    templateColumns={{
                      sm: 'repeat(1, 1fr)',
                      md: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem>
                      <FormControl>
                        <FormLabel>CNPJ</FormLabel>
                        <Input
                          as={IMaskInput}
                          mask="00.000.000/0000-00"
                          isDisabled
                          type="text"
                          value={selectedCompany?.registrationNumber}
                        />
                      </FormControl>
                    </GridItem>

                    <GridItem>
                      <FormControl isInvalid={!!errors.company?.name}>
                        <FormLabel>Razão social</FormLabel>
                        <Input
                          type="text"
                          placeholder="Nome empresarial registrado"
                          {...register('company.name')}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="company.name" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem>
                      <FormControl>
                        <FormLabel>Nome fantasia (opcional)</FormLabel>
                        <Input
                          type="text"
                          placeholder="Nome comercial"
                          {...register('company.tradeName')}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box>

                <Box>
                  <Text fontWeight={600} marginBottom={4}>
                    Endereço fiscal da empresa
                  </Text>

                  <Grid
                    templateColumns={{
                      sm: 'repeat(1, 1fr)',
                      md: 'repeat(6, 1fr)',
                      lg: 'repeat(12, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem
                      colSpan={{
                        base: 5,
                        md: 1,
                        lg: 2,
                      }}
                    >
                      <FormControl isInvalid={!!errors.address?.zipCode}>
                        <FormLabel>CEP</FormLabel>
                        <Controller
                          control={control}
                          name="address.zipCode"
                          render={({ field }) => (
                            <Input
                              as={IMaskInput}
                              inputRef={field.ref}
                              type="text"
                              placeholder="00000-000"
                              mask="00000-000"
                              unmask={true}
                              value={field.value}
                              onAccept={(value: string) => {
                                setValue('address.zipCode', value);
                              }}
                            />
                          )}
                        />

                        <FormErrorMessage>
                          <ErrorMessage
                            errors={errors}
                            name="address.zipCode"
                          />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem colSpan={5}>
                      <FormControl isInvalid={!!errors.address?.address}>
                        <FormLabel>Endereço</FormLabel>
                        <Input
                          type="text"
                          placeholder="Rua/Avenida"
                          {...register('address.address')}
                          maxLength={addressMaxLength.address}
                        />
                        <FormErrorMessage>
                          <ErrorMessage
                            errors={errors}
                            name="address.address"
                          />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem colSpan={2}>
                      <FormControl isInvalid={!!errors.address?.number}>
                        <FormLabel>Número</FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          isDisabled={addressWithoutNumber}
                          {...register('address.number')}
                          maxLength={addressMaxLength.number}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="address.number" />
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox
                        isChecked={addressWithoutNumber}
                        onChange={(e) => {
                          setAddressWithoutNumber(e.target.checked);
                          setValue('address.number', '');
                          clearErrors('address.number');
                        }}
                        marginTop={2}
                      >
                        Sem número
                      </Checkbox>
                    </GridItem>

                    <GridItem colSpan={3}>
                      <FormControl isInvalid={!!errors.address?.complement}>
                        <FormLabel>Complemento</FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          isDisabled={addressWithoutComplement}
                          {...register('address.complement')}
                          maxLength={addressMaxLength.complement}
                        />
                        <FormErrorMessage>
                          <ErrorMessage
                            errors={errors}
                            name="address.complement"
                          />
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox
                        isChecked={addressWithoutComplement}
                        onChange={(e) => {
                          setAddressWithoutComplement(e.target.checked);
                          setValue('address.complement', '');
                          clearErrors('address.complement');
                        }}
                        marginTop={2}
                      >
                        Sem complemento
                      </Checkbox>
                    </GridItem>
                  </Grid>

                  <Grid
                    templateColumns={{
                      sm: 'repeat(1, 1fr)',
                      md: 'repeat(6, 1fr)',
                      lg: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                    marginTop={6}
                  >
                    <GridItem>
                      <FormControl isInvalid={!!errors.address?.district}>
                        <FormLabel>Bairro/Distrito</FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          {...register('address.district')}
                          maxLength={addressMaxLength.district}
                        />
                        <FormErrorMessage>
                          <ErrorMessage
                            errors={errors}
                            name="address.district"
                          />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem>
                      <FormControl isInvalid={!!errors.address?.city}>
                        <FormLabel>Cidade</FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          {...register('address.city')}
                          maxLength={addressMaxLength.city}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="address.city" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem>
                      <FormControl isInvalid={!!errors.address?.state}>
                        <FormLabel htmlFor="state">Estado</FormLabel>
                        <Controller
                          name="address.state"
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputId="state"
                              options={UFs}
                              value={UFs.find(
                                (state) => state.value === field.value
                              )}
                              onChange={(state) => field.onChange(state?.value)}
                              onBlur={field.onBlur}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="address.state" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box>
              </Flex>
              <Text marginTop={6} color="brand.aux08">
                O endereço fiscal da empresa fica{' '}
                <Semibold>
                  registrado nas cobranças (Boleto/Pix) e nas notas fiscais
                </Semibold>{' '}
                das recargas criadas
              </Text>
            </CardBody>

            <CardFooter paddingTop={0}>
              <Button
                type="submit"
                width="290px"
                marginTop={4}
                marginLeft="auto"
                isLoading={updateCompanyInfo.isPending}
              >
                Confirmar
              </Button>
            </CardFooter>
          </form>
        </Card>
      </Box>
    </FirstAccessLayout>
  );
};

export default CompanyRegistrationPage;
