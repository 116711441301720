import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from '@chakra-ui/react';

const base: ThemeOverride = {
  styles: {
    global: {
      'html, body ': {
        minHeight: '100vh',
        bg: '#f3f3f3',
        color: '#060606',
      },
    },
  },
  fonts: {
    body: `'Graphik'`,
    heading: `'Lexend'`,
  },
  colors: {
    brand: {
      primary: '#DE8706',
      aux00: '#F4FE5C',
      aux01: '#3C4012',
      aux02: '#B4BF37',
      aux03: '#DE8706',
      aux04: '#3D4294',
      aux05: '#7A7EC8',
      aux06: '#C3C5E6',
      aux07: '#D2E101',
      aux08: '#BB4211',
      aux09: '#7B2603',
      aux10: '#E79746',
      aux11: '#ECB019',
      yellowMain: '#FAEF72',
      pink: '#BF3F75',
    },
    light: {
      green: 'rgba(30, 117, 0, 0.10)',
      orange: 'rgba(222, 135, 6, 0.10)',
      blue: 'rgba(61, 66, 148, 0.10)',
      gray: 'rgba(53, 53, 53, 0.10)',
      red: 'rgba(219, 2, 0, 0.10)',
      pink: 'rgba(191, 63, 117, 0.10)',
    },
    feedback: {
      success: '#1e7500',
      alert: '#db0200',
    },
    benefits: {
      border: {
        remoteWork: '#26335D',
        foodAndMeal: '#8F0303',
        food: '#C9320D',
        meal: '#EE4B68',
        mobility: '#AC41A1',
        fuel: '#603FBF',
        health: '#5B99E2',
        culture: '#DF6A00',
        education: '#22A968',
        allInOne: '#FFC700',
        awards: '#7CAF96',
        incentive: '#609AAC',
        freeBalance: '#2DB393',
        attendance: '#6978FF',
        birthday: '#D860D4',
        christmas: '#CA5C39',
      },
      background: {
        remoteWork: 'rgba(38, 51, 93, 0.10)',
        foodAndMeal: 'rgba(152, 36, 0, 0.10)',
        food: 'rgba(201, 50, 13, 0.10)',
        meal: 'rgba(201, 50, 13, 0.10)',
        mobility: 'rgba(172, 65, 161, 0.10)',
        fuel: 'rgba(96, 63, 191, 0.10)',
        health: 'rgba(91, 153, 226, 0.10)',
        culture: 'rgba(223, 106, 0, 0.10)',
        education: 'rgba(34, 169, 104, 0.10)',
        allInOne: 'rgba(255, 199, 0, 0.06)',
        awards: 'rgba(124, 175, 150, 0.10)',
        incentive: 'rgba(96, 154, 172, 0.10)',
        freeBalance: 'rgba(45, 179, 147, 0.10)',
        attendance: 'rgba(105, 120, 255, 0.10)',
        birthday: 'rgba(216, 96, 212, 0.10)',
        christmas: 'rgba(202, 92, 57, 0.10)',
      },
    },
    gray: {
      25: ' rgba(195, 195, 195, 0.20)',
      50: '#ffffff',
      75: '#fbfbfb',
      100: '#f3f3f3',
      200: '#e3e3e3',
      300: '#dadada',
      400: '#c7c7c7',
      500: '#c3c3c3',
      550: '#C4C4C4',
      600: '#a0a0a0',
      700: '#767674',
      800: '#353535',
      900: '#060606',
    },
    orange: {
      50: '#fff4db',
      100: '#ffe0b0',
      200: '#fdcb80',
      300: '#fab64f',
      400: '#f9a120',
      500: '#de8706',
      600: '#ae6a02',
      700: '#7d4b00',
      800: '#4c2c00',
      900: '#1e0e00',
    },
  },

  shadows: {
    outline: '0 0 0 3px #ae6a02',
  },

  components: {
    Card: {
      baseStyle: {
        container: {
          position: 'unset',
          bg: '#fffff',
          borderRadius: 'xl',
        },
        header: {
          padding: '24px',
        },
        body: {
          padding: '24px',
        },
        footer: {
          padding: '24px',
        },
      },
    },

    Input: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.700',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.900',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.primary',
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.700',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.900',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.primary',
      },
    },

    Select: {
      baseStyle: {
        field: {
          borderRadius: 'lg',
          padding: '16px',
          height: '56px',
          _placeholder: {
            color: 'gray.700',
          },
          _disabled: {
            bg: 'gray.300',
            _placeholder: {
              color: 'gray.900',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.primary',
      },
    },

    Spinner: {
      baseStyle: {
        color: 'brand.primary',
      },
    },

    Button: {
      baseStyle: {
        fontWeight: 700,
      },
      defaultProps: {
        focusBorderColor: 'orange.200',
        colorScheme: 'orange',
      },
      sizes: {
        md: {
          h: '56px',
          px: '32px',
          borderRadius: '28px',
          width: '345px',
        },
      },
      variants: {
        link: {
          color: 'inherit',
          width: 'fit-content',
          padding: '4px 8px',
          textDecoration: 'underline',
          fontWeight: 400,
          fontSize: 'md',
          _hover: {
            _disabled: {
              textDecoration: 'underline',
            },
          },
          _disabled: {
            _active: {
              color: 'inherit',
            },
          },
        },
        ghost: {
          width: 'fit-content',
          height: 'fit-content',
          padding: '8px 16px',
          borderRadius: 'md',
          fontWeight: 400,
          color: 'gray.800',
          _hover: {
            bg: 'none',
          },
        },
        outline: {
          color: 'brand.primary',
        },
        pagination: {
          boxSize: '40px',
          backgroundColor: 'gray.50',
          color: 'gray.800',
          borderRadius: 'lg',
          svg: {
            width: '24px',
            height: '24px',
          },
          _hover: {
            backgroundColor: 'gray.200',
            opacity: 1,
            _disabled: {
              backgroundColor: 'gray.200',
            },
          },
          _disabled: {
            opacity: 1,
            backgroundColor: 'gray.50',
            cursor: 'pointer',
          },
        },
      },
    },

    FormLabel: {
      baseStyle: {
        fontWeight: 400,
        marginBottom: 1,
      },
    },

    Radio: {
      defaultProps: {
        colorScheme: 'orange',
      },
      sizes: {
        xl: {
          control: {
            width: '24px',
            height: '24px',
          },
        },
      },
    },

    Tooltip: {
      baseStyle: {
        bg: 'gray.900',
        color: 'gray.50',
        padding: 'lg',
        borderRadius: 'sm',
        fontWeight: 600,
        fontSize: 'md',
      },
    },

    Checkbox: {
      defaultProps: {
        colorScheme: 'orange',
      },
    },

    Divider: {
      baseStyle: {
        borderColor: 'gray.500',
      },
    },

    Modal: {
      baseStyle: {
        overlay: {
          background: 'blackAlpha.600',
          backdropFilter: 'blur(4px)',
        },
        dialog: {
          padding: 4,
        },
        closeButton: {
          top: 8,
          right: 8,
        },
      },
    },

    Drawer: {
      variants: {
        clickThrough: {
          dialogContainer: {
            pointerEvents: 'none',
          },
          dialog: {
            pointerEvents: 'auto',
            boxShadow:
              'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
          },
        },
      },
    },

    Tabs: {
      baseStyle: {
        root: {},
        tab: {
          _active: {
            bg: 'gray.100',
          },
          _selected: {
            color: 'brand.primary',
            borderColor: 'brand.primary',
            borderBottomColor: 'brand.primary',
            fontWeight: '600',
            borderBottom: '2px solid',
          },
        },
        tablist: {},
      },
    },

    Link: {
      baseStyle: {
        textDecoration: 'underline',
      },
    },

    Table: {
      defaultProps: {
        colorScheme: 'gray',
      },
      baseStyle: {
        table: {
          backgroundColor: 'gray.50',
          padding: '24px',
          borderRadius: 'md',
        },
        thead: {
          th: {
            color: 'inherit',
            fontSize: 'md',
            fontWeight: 600,
            paddingX: 8,
            paddingBottom: 8,
            paddingTop: 4,
            textTransform: 'unset',
            borderBottom: '1px solid',
            borderColor: 'gray.500',
            fontFamily: 'body',
            letterSpacing: 'unset',
          },
        },
        tbody: {
          tr: {
            td: {
              paddingY: 4,
              paddingX: 8,
            },
          },
        },
      },
      variants: {
        raw: {
          table: {},
          thead: {
            th: {
              paddingX: 6,
              paddingY: 4,
              borderColor: 'gray.700',
            },
          },
          tbody: {
            tr: {
              borderColor: 'gray.700',
              td: {
                paddingY: 4,
                paddingX: 6,
                borderColor: 'gray.700',
              },
            },
          },
        },
      },
    },

    Popover: {
      baseStyle: {
        closeButton: {
          top: 2,
          right: 2,
        },
      },
    },

    Menu: {
      baseStyle: {
        list: {
          boxShadow: 'md',
        },
      },
    },
    Badge: {
      variants: {
        green: {
          padding: '4px 8px',
          borderRadius: '16px',
          color: 'feedback.success',
          background: 'light.green',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        blue: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'light.blue',
          color: 'brand.aux04',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        orange: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'light.orange',
          color: 'brand.aux03',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        pink: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'light.pink',
          color: 'brand.pink',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        gray: {
          padding: '4px 8px',
          borderRadius: '16px',
          background: 'light.gray',
          color: 'gray.800',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
      },
    },

    Skeleton: {
      baseStyle: {
        borderRadius: 'xl',
      },
    },
  },
};

export const theme = extendTheme(
  withDefaultColorScheme({ colorScheme: 'orange' }),
  base
);
