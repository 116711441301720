import {
  Box,
  Divider,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import Avatar from '../Avatar';
import { FaInstagram } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { FiLogOut, FiPhone, FiUser } from 'react-icons/fi';
import PortalTermsOfUseLink from '../PortalTermsOfUseLink';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import EditProfileModal from '../EditProfileModal';
import Title from '../Title';
import { useNavigate } from 'react-router-dom';
import ProfileContactForms from './ContactFormsModal';

const Profile = () => {
  const { authState, removeAuthInfo } = useAuthContext();
  const { onOpen } = useDisclosure();
  const { user } = authState;

  const navigate = useNavigate();
  const editProfileModal = useDisclosure();
  const profileContactFormsModal = useDisclosure();

  if (!user) return null;

  return (
    <Menu>
      <MenuButton as={'button'} aria-label="perfil do usuário">
        <Box onClick={onOpen} cursor="pointer">
          <Avatar name={user?.fullName} marginX={3} />
        </Box>
      </MenuButton>
      <MenuList width={'440px'} marginTop={4}>
        <Box>
          <Flex marginTop={8} marginLeft={8} marginBottom={4}>
            <Title>{user.fullName}</Title>
          </Flex>
          <Text
            padding={'0px 32px'}
            fontSize="16px"
            fontWeight={400}
            marginBottom="2"
            lineHeight={'130%'}
          >
            {user.username}
          </Text>
          <Text
            padding={'0px 32px'}
            fontSize="16px"
            fontWeight={400}
            _firstLetter={{ textTransform: 'uppercase' }}
            marginBottom="6"
            lineHeight={'130%'}
          >
            {user.policy?.name?.toLocaleLowerCase()}
          </Text>
          <MenuItem
            padding={'0px 32px'}
            height={14}
            icon={<Icon as={FiUser} width={'24px'} height="24px" />}
            onClick={editProfileModal.onOpen}
            fontWeight={400}
            fontSize={16}
            color="gray.800"
            aria-label="editar meus dados"
          >
            Editar meus dados
          </MenuItem>
          <MenuItem
            padding={'0px 32px'}
            icon={
              <Icon as={FiPhone} color="gray.800" width="24px" height="24px" />
            }
            onClick={profileContactFormsModal.onOpen}
            height={14}
            fontWeight={400}
            fontSize={16}
            color="gray.800"
            aria-label="preciso de ajuda"
          >
            Preciso de ajuda
          </MenuItem>

          <Divider
            orientation="vertical"
            height="1px"
            marginLeft="auto"
            marginRight="auto"
            width={'376px'}
            background="gray.550"
          />
          <MenuItem
            padding={'0px 32px'}
            height={16}
            icon={<Icon as={FiLogOut} width={'24px'} height="24px" />}
            onClick={() => {
              removeAuthInfo();
              navigate('/login');
            }}
            fontWeight={400}
            fontSize={16}
            color="gray.800"
            arila-label="sair do portal"
          >
            Sair do portal
          </MenuItem>
          <Divider
            orientation="vertical"
            height="1px"
            marginLeft="auto"
            marginRight="auto"
            width={'376px'}
            background="gray.550"
            marginBottom={8}
          />
          <Box padding={'0px 32px'}>
            <Text fontSize="16px" fontWeight={600} mb={2} color="gray.800">
              Raiô Benefícios Ltda
            </Text>
            <Text fontSize="14px" color="gray.700">
              CNPJ 49.854.823/0001-73
            </Text>
            <Text fontSize="14px" color="gray.700">
              Inscrição no PAT: 230813350
            </Text>
            <Flex marginTop="6" gap={2}>
              <MenuItem
                _hover={{
                  background: 'none',
                }}
                width={'max-content'}
                padding={0}
                as="a"
                href="https://www.instagram.com/raiobeneficios"
                target="_blank"
              >
                <Box
                  width="32px"
                  height="32px"
                  bg="gray.800"
                  p="5px"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FaInstagram size="22px" fill="white" />
                </Box>
              </MenuItem>
              <MenuItem
                _hover={{
                  background: 'none',
                }}
                width={'max-content'}
                padding={0}
                as="a"
                href="https://www.linkedin.com/company/raio-beneficios/"
                target="blank"
              >
                <Box
                  width="32px"
                  height="32px"
                  bg="gray.800"
                  p="5px"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FaLinkedinIn size="22px" fill="white" />
                </Box>
              </MenuItem>
            </Flex>
          </Box>
          <Flex
            gap="2"
            flexDirection={'column'}
            padding={'24px 32px 32px 32px'}
          >
            <Box>
              <PortalTermsOfUseLink color="brand.aux08" fontWeight={500} />
            </Box>
            <Box>
              <PrivacyPolicyLink color="brand.aux08" fontWeight={500} />
            </Box>
          </Flex>
        </Box>
      </MenuList>
      {editProfileModal.isOpen && (
        <EditProfileModal
          isOpen={editProfileModal.isOpen}
          onClose={editProfileModal.onClose}
        />
      )}
      {profileContactFormsModal.isOpen && (
        <ProfileContactForms
          isOpen={profileContactFormsModal.isOpen}
          onClose={profileContactFormsModal.onClose}
        />
      )}
    </Menu>
  );
};

export default Profile;
