import { Divider, Flex, Text } from '@chakra-ui/react';
import LogoutButton from '../LogoutButton';
import Profile from '../Profile';
import { protectedRoutes } from '../../routes';
import CompanySelect from '../CompanySelect';

type HeaderProps = {
  isOpen: boolean;
};

function removeUUIDFromPathname(pathname: string) {
  const uuidRegex =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

  const cleanPathname = pathname.replace(uuidRegex, '').replace(/\/$/, '');

  return cleanPathname;
}

function removeDynamicSegmentFromRoutePath(pathname: string) {
  const paramRegex = /\/:[^/]+/g;

  const cleanPathname = pathname.replace(paramRegex, '');

  return cleanPathname;
}

function getPageName(pathname: string): string {
  const cleanPathname = removeUUIDFromPathname(pathname);

  const route = protectedRoutes.find(
    (route) => removeDynamicSegmentFromRoutePath(route.path) === cleanPathname
  );

  if (!route) {
    return '';
  }

  return route.title;
}

const Header = ({ isOpen }: HeaderProps) => {
  const { pathname } = window.location;

  return (
    <Flex
      id="app-shell-header"
      as="section"
      justifyContent="space-between"
      alignItems="center"
      padding="20px 16px"
      paddingLeft={isOpen ? 72 : 28}
      transition={'padding-left 0.3s'}
      height="90px"
      backgroundColor="gray.50"
    >
      <Text fontSize="24px" fontWeight={600}>
        {getPageName(pathname)}
      </Text>

      <Flex id="app-shell-header-right" alignItems="center" gap={4}>
        <CompanySelect />

        <Profile />
      </Flex>
    </Flex>
  );
};

export default Header;
