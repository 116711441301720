import { useAuthContext } from '../../hooks/useAuthContext';
import { Button } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const { removeAuthInfo } = useAuthContext();
  const navigate = useNavigate();
  return (
    <Button
      padding={'16px 16px 16px 0'}
      variant="ghost"
      gap={2}
      leftIcon={<FiLogOut size="24px" />}
      onClick={() => {
        // TODO: request to invalidate token
        removeAuthInfo();
        navigate('/login');
      }}
    >
      Sair do portal
    </Button>
  );
};

export default LogoutButton;
