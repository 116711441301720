import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IMaskInput } from 'react-imask';
import { ErrorMessage } from '@hookform/error-message';
import {
  useConsultAddresByZipCode,
  useCreateAddress,
} from '../../../../api/address';
import Spinner from '../../../../components/Spinner';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { addressMaxLength, UFs } from '../../../../constants/general';
import Select from '../../../../components/Select';
import CheckboxCard from '../../../../components/CheckboxCard';
import { Address, EntityTypeEnum } from '../../../../types/address';
import { BuildingIcon, HomeIcon } from '../../../../assets/customIcons';

const ADDRESS_REQUIRED_MESSAGE = 'O endereço é obrigatório';
const CITY_REQUIRED_MESSAGE = 'A cidade é obrigatória';
const DISTRICT_REQUIRED_MESSAGE = 'O bairro é obrigatório';
const STATE_REQUIRED_MESSAGE = 'O estado é obrigatório';

const addressSchema = z
  .object({
    addressEntity: z.nativeEnum(EntityTypeEnum, {
      required_error: 'Selecione o tipo de endereço',
    }),
    address: z
      .string({
        invalid_type_error: ADDRESS_REQUIRED_MESSAGE,
        required_error: ADDRESS_REQUIRED_MESSAGE,
      })
      .min(1, { message: ADDRESS_REQUIRED_MESSAGE }),
    number: z.string().nullish(),
    zipCode: z
      .string({ required_error: 'O CEP é obrigatório' })
      .min(8, { message: 'CEP inválido' }),
    complement: z.string().nullish(),
    city: z
      .string({
        invalid_type_error: CITY_REQUIRED_MESSAGE,
        required_error: CITY_REQUIRED_MESSAGE,
      })
      .min(1, { message: CITY_REQUIRED_MESSAGE }),
    district: z
      .string({
        invalid_type_error: DISTRICT_REQUIRED_MESSAGE,
        required_error: DISTRICT_REQUIRED_MESSAGE,
      })
      .min(1, { message: DISTRICT_REQUIRED_MESSAGE }),
    state: z
      .string({ required_error: STATE_REQUIRED_MESSAGE })
      .min(1, STATE_REQUIRED_MESSAGE),
    name: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.addressEntity === EntityTypeEnum.COMPANY && !data.name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'O nome do endereço é obrigatório',
        path: ['name'],
      });
    }
  });

type FormValues = z.infer<typeof addressSchema> & {
  addressWithoutNumber: boolean;
  addressWithoutComplement: boolean;
};

type CreatableAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  hasFilledEmployeeAddress: boolean;
  setEmployeeAddress: (address: Partial<Address>) => void;
  onCreateCollaboratorAddress?: (
    address: Partial<Address>
  ) => Promise<string | undefined>;
};

const employeeAddressId = crypto.randomUUID();

const CreatableAddressModal = ({
  isOpen,
  onClose,
  hasFilledEmployeeAddress,
  setEmployeeAddress,
  onCreateCollaboratorAddress,
}: CreatableAddressModalProps) => {
  const [addressWithoutNumber, setAddressWithoutNumber] = useState(false);
  const [addressWithoutComplement, setAddressWithoutComplement] =
    useState(false);
  const [createCollaboratorAddresLoading, setCreateCollaboratorAddresLoading] =
    useState(false);
  const { selectedCompany } = useUserCompaniesStore();

  const {
    control,
    register,
    formState: { errors },
    setValue,
    trigger,
    reset,
    getValues,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(addressSchema),
  });

  const { setValue: setCheckboxValue } = useCheckboxGroup();

  const zipCode = watch('zipCode');
  const state = watch('state');
  const addressEntity = watch('addressEntity');

  const {
    data: address,
    isFetching,
    isFetched,
    isError,
  } = useConsultAddresByZipCode(zipCode || '');
  const isErrorConsult = address?.erro || isError || false;

  const onConsultAddressByZipCode = useCallback(() => {
    if (address) {
      setValue('address', address.logradouro);
      setValue('district', address.bairro);
      setValue('city', address.localidade);
      setValue('state', address.uf);
    }
  }, [address, setValue]);

  useEffect(() => {
    onConsultAddressByZipCode();
  }, [onConsultAddressByZipCode]);

  function resetAddressForm() {
    reset({
      address: '',
      number: '',
      district: '',
      complement: '',
      addressWithoutNumber: false,
      addressWithoutComplement: false,
      city: '',
      state: '',
    });
  }
  const modalContainerRef = useRef<HTMLDivElement>(null);

  async function handleOnClickCancel() {
    reset({
      address: '',
      number: '',
      district: '',
      zipCode: '',
      complement: '',
      name: '',
      addressWithoutNumber: false,
      addressWithoutComplement: false,
      city: '',
      state: '',
    });
    onClose();
  }

  const addAddress = useCreateAddress();

  async function handleOnClickCreate(values: FormValues) {
    trigger().then(async (isValid) => {
      if (!isValid) return;

      if (addressEntity === EntityTypeEnum.COMPANY) {
        addAddress.mutate(
          {
            address: {
              ...values,
              entityType: 'COMPANY',
              country: 'Brasil',
              entityId: selectedCompany?.id ?? '',
              state: values.state,
            },
          },
          {
            onSuccess: () => {
              reset();
              onClose();
            },
          }
        );
      } else if (addressEntity === 'COLLABORATOR') {
        if (onCreateCollaboratorAddress) {
          setCreateCollaboratorAddresLoading(true);
          await onCreateCollaboratorAddress(values)
            .then((id) => {
              setCreateCollaboratorAddresLoading(false);
              setEmployeeAddress({
                ...values,
                id: id,
              });
              reset();
              onClose();
            })
            .catch(() => {
              setCreateCollaboratorAddresLoading(false);
            });
        } else {
          setEmployeeAddress(values);
          reset();
          onClose();
        }
      }
    });
  }

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = e.target.value;
      const checked = e.target.checked;

      if (checked) {
        setCheckboxValue([nextValue]);
        setValue('addressEntity', nextValue as EntityTypeEnum);
        if (nextValue === EntityTypeEnum.COLLABORATOR) {
          setValue('name', '');
        }
      }

      trigger(['addressEntity', 'name']);
    },
    [setCheckboxValue, setValue, trigger]
  );

  useEffect(() => {
    if (hasFilledEmployeeAddress && !addressEntity) {
      setCheckboxValue([EntityTypeEnum.COMPANY]);
      setValue('addressEntity', EntityTypeEnum.COMPANY);
    }
  }, [hasFilledEmployeeAddress, addressEntity, setCheckboxValue, setValue]);

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleOnClickCancel}
          isCentered
          size="4xl"
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <form>
            <ModalContent ref={modalContainerRef}>
              <ModalHeader>Novo endereço</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column" gap={4}>
                  <FormControl marginY={6} isInvalid>
                    <CheckboxGroup>
                      <Flex gap={4}>
                        <CheckboxCard
                          value={EntityTypeEnum.COMPANY}
                          isChecked={addressEntity === EntityTypeEnum.COMPANY}
                          onChange={handleCheckboxChange}
                          color={
                            addressEntity === EntityTypeEnum.COMPANY
                              ? 'gray.800'
                              : 'gray.700'
                          }
                          minWidth="292px"
                        >
                          <VStack align="flex-start">
                            <Icon as={BuildingIcon} boxSize="24px" />
                            <Text fontWeight={600} marginY={4}>
                              Endereço da empresa
                            </Text>
                          </VStack>
                        </CheckboxCard>
                        {!hasFilledEmployeeAddress && (
                          <CheckboxCard
                            value={EntityTypeEnum.COLLABORATOR}
                            isChecked={
                              addressEntity === EntityTypeEnum.COLLABORATOR
                            }
                            onChange={handleCheckboxChange}
                            color={
                              addressEntity === EntityTypeEnum.COLLABORATOR
                                ? 'gray.800'
                                : 'gray.700'
                            }
                            minWidth="292px"
                          >
                            <VStack align="flex-start">
                              <Icon as={HomeIcon} boxSize="24px" />
                              <Text fontWeight={600} marginY={4}>
                                Endereço do colaborador
                              </Text>
                            </VStack>
                          </CheckboxCard>
                        )}
                      </Flex>
                    </CheckboxGroup>
                    <FormErrorMessage marginTop={4}>
                      <ErrorMessage errors={errors} name="addressEntity" />
                    </FormErrorMessage>
                  </FormControl>

                  <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                    <GridItem colSpan={12}>
                      <FormControl isInvalid={!!errors.name}>
                        <FormLabel fontSize="sm">
                          Que nome você quer dar a esse endereço?
                        </FormLabel>
                        <Input
                          {...register('name')}
                          isDisabled={
                            addressEntity === EntityTypeEnum.COLLABORATOR
                          }
                          placeholder={
                            addressEntity === EntityTypeEnum.COLLABORATOR
                              ? 'Colaborador'
                              : ''
                          }
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="name" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem colSpan={3}>
                      <FormControl
                        isInvalid={!!errors.zipCode}
                        position="relative"
                      >
                        <FormLabel fontSize="sm">CEP</FormLabel>
                        <Controller
                          control={control}
                          name="zipCode"
                          render={({ field }) => (
                            <Input
                              ref={field.ref}
                              as={IMaskInput}
                              inputRef={field.ref}
                              type="text"
                              mask="00000-000"
                              unmask={true}
                              value={field.value}
                              onAccept={(value: string) => {
                                setValue('zipCode', value);

                                if (value.length < 8 && state) {
                                  resetAddressForm();
                                }
                              }}
                              paddingRight={isFetching ? '32px' : '16px'}
                              onBlur={() => {
                                trigger('zipCode');
                              }}
                            />
                          )}
                        />
                        {isFetching && (
                          <Spinner
                            size="sm"
                            centerProps={{
                              position: 'absolute',
                              top: '45px',
                              right: '16px',
                              margin: '0',
                            }}
                          />
                        )}
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="zipCode" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={9}>
                      <FormControl isInvalid={!!errors.address}>
                        <FormLabel fontSize="sm">Endereço</FormLabel>
                        <Input
                          type="text"
                          disabled={!isFetched}
                          {...register('address')}
                          maxLength={addressMaxLength.address}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="address" />
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <FormControl>
                        <FormLabel fontSize="sm">Número</FormLabel>
                        <Input
                          type="text"
                          {...register('number')}
                          isDisabled={addressWithoutNumber || !isFetched}
                          maxLength={addressMaxLength.number}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="number" />
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox
                        isChecked={addressWithoutNumber}
                        {...register('addressWithoutNumber')}
                        onChange={() => {
                          setAddressWithoutNumber(!addressWithoutNumber);
                          trigger(['number', 'addressWithoutNumber']);
                          setValue('number', '');
                        }}
                        marginTop={2}
                        isDisabled={!isFetched}
                      >
                        Sem número
                      </Checkbox>
                    </GridItem>
                    <GridItem colSpan={9}>
                      <FormControl>
                        <FormLabel fontSize="sm">Complemento</FormLabel>
                        <Input
                          type="text"
                          {...register('complement')}
                          isDisabled={addressWithoutComplement || !isFetched}
                          maxLength={addressMaxLength.complement}
                        />
                        <FormErrorMessage>
                          <ErrorMessage errors={errors} name="complement" />
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox
                        isChecked={addressWithoutComplement}
                        {...register('addressWithoutComplement')}
                        onChange={() => {
                          setAddressWithoutComplement(
                            !addressWithoutComplement
                          );
                          trigger(['complement', 'addressWithoutComplement']);
                          setValue('complement', '');
                        }}
                        marginTop={2}
                        isDisabled={!isFetched}
                      >
                        Sem complemento
                      </Checkbox>
                    </GridItem>
                  </Grid>

                  <Flex gap={4}>
                    <FormControl isInvalid={!!errors.district}>
                      <FormLabel fontSize="sm">Bairro/Distrito</FormLabel>
                      <Input
                        type="text"
                        disabled={!isFetched}
                        {...register('district')}
                        maxLength={addressMaxLength.district}
                      />
                      <FormErrorMessage>
                        <ErrorMessage errors={errors} name="district" />
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.city}>
                      <FormLabel fontSize="sm">Cidade</FormLabel>
                      <Input
                        type="text"
                        disabled={!isFetched || !isErrorConsult}
                        {...register('city')}
                        maxLength={addressMaxLength.city}
                      />
                      <FormErrorMessage>
                        <ErrorMessage errors={errors} name="city" />
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.state}>
                      <FormLabel fontSize="sm">Estado</FormLabel>
                      <Controller
                        control={control}
                        name="state"
                        render={({ field }) => {
                          return (
                            <Select
                              placeholder=""
                              isDisabled={!isFetched || !isErrorConsult}
                              options={UFs}
                              value={UFs.find((uf) => uf.value === field.value)}
                              onChange={(value) => {
                                field.onChange(value?.value);
                              }}
                            />
                          );
                        }}
                      />
                      <FormErrorMessage>
                        <ErrorMessage errors={errors} name="state" />
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="outline"
                  width="160px"
                  mr={3}
                  onClick={handleOnClickCancel}
                >
                  Cancelar
                </Button>
                <Button
                  width="160px"
                  isLoading={
                    addAddress.isPending || createCollaboratorAddresLoading
                  }
                  onClick={() => {
                    const values = getValues();
                    handleOnClickCreate(values);
                  }}
                >
                  Salvar
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </>
  );
};
export default CreatableAddressModal;
