import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Address } from '../../types/address';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { addressMaxLength, UFs } from '../../constants/general';
import Spinner from '../../components/Spinner';
import CustomSelect from '../../components/Select';
import { useEffect, useState } from 'react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import {
  useConsultAddresByZipCode,
  useCreateAddress,
  useUpdateAddress,
} from '../../api/address';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Semibold } from '../../components/Typography';

type AddAndEditAddresModalProps = {
  isOpen: boolean;
  onClose: () => void;
  addressToEdit?: Address | null;
};
const ADDRESS_REQUIRED_MESSAGE = 'O endereço é obrigatório';
const CITY_REQUIRED_MESSAGE = 'A cidade é obrigatória';
const DISTRICT_REQUIRED_MESSAGE = 'O bairro é obrigatório';
const STATE_REQUIRED_MESSAGE = 'O estado é obrigatório';

const companyAddressSchema = z.object({
  id: z.string().optional(),
  address: z
    .string({
      invalid_type_error: ADDRESS_REQUIRED_MESSAGE,
      required_error: ADDRESS_REQUIRED_MESSAGE,
    })
    .min(1, { message: ADDRESS_REQUIRED_MESSAGE }),
  number: z.string().nullish(),
  zipCode: z
    .string({ required_error: 'O CEP é obrigatório' })
    .min(8, { message: 'CEP inválido' }),
  complement: z.string().nullish(),
  city: z
    .string({
      invalid_type_error: CITY_REQUIRED_MESSAGE,
      required_error: CITY_REQUIRED_MESSAGE,
    })
    .min(1, { message: CITY_REQUIRED_MESSAGE }),
  district: z
    .string({
      invalid_type_error: DISTRICT_REQUIRED_MESSAGE,
      required_error: DISTRICT_REQUIRED_MESSAGE,
    })
    .min(1, { message: DISTRICT_REQUIRED_MESSAGE }),
  state: z
    .string({ required_error: STATE_REQUIRED_MESSAGE })
    .min(1, STATE_REQUIRED_MESSAGE),
  name: z.string().min(1, { message: 'O nome do endereço é obrigatório' }),
});

type FormValues = z.infer<typeof companyAddressSchema> & {
  addressWithoutNumber: boolean;
  addressWithoutComplement: boolean;
};

const AddAndEditAddressModal = ({
  isOpen,
  onClose,
  addressToEdit,
}: AddAndEditAddresModalProps) => {
  const [addressWithoutNumber, setAddressWithoutNumber] = useState(false);
  const [addressWithoutComplement, setAddressWithoutComplement] =
    useState(false);
  const { selectedCompany } = useUserCompaniesStore();
  const [shouldQuery, setShouldQuery] = useState(false);
  const isEdit = !!addressToEdit;
  const {
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
    trigger,
    handleSubmit,
    reset,
    getValues,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(companyAddressSchema),
    values: {
      address: addressToEdit?.address || '',
      number: addressToEdit?.number || '',
      zipCode: addressToEdit?.zipCode || '',
      complement: addressToEdit?.complement || '',
      city: addressToEdit?.city || '',
      district: addressToEdit?.district || '',
      state: addressToEdit?.state || '',
      name: addressToEdit?.name || '',
      addressWithoutNumber: !addressToEdit?.number,
      addressWithoutComplement: !addressToEdit?.complement,
    },
  });

  const zipCode = watch('zipCode');
  const state = watch('state');
  const {
    data: address,
    isFetching,
    isFetched,
    isError,
  } = useConsultAddresByZipCode(zipCode);

  const isErrorConsult = address?.erro || isError || false;
  const isTaxAddress: boolean = addressToEdit?.addressType === 'TAX';

  useEffect(() => {
    if (address && shouldQuery) {
      setValue('address', address.logradouro);
      setValue('district', address.bairro);
      setValue('city', address.localidade);
      setValue('state', address.uf);
    }
  }, [address, shouldQuery, setValue]);

  function resetAddressForm() {
    reset({
      address: '',
      number: '',
      district: '',
      complement: '',
      addressWithoutNumber: false,
      addressWithoutComplement: false,
      city: '',
      state: '',
      name: isTaxAddress ? addressToEdit?.name : getValues('name'),
    });
  }

  async function handleOnClickCancel() {
    resetAddressForm();
    onClose();
  }

  const addAddress = useCreateAddress();
  const editAddressData = useUpdateAddress({
    addressId: addressToEdit?.id,
  });

  async function handleSave(values: FormValues) {
    if (addressToEdit) {
      editAddressData.mutate(
        {
          address: {
            ...values,
            id: addressToEdit?.id ?? '',
            country: 'Brasil',
          },
        },
        {
          onSuccess: () => {
            reset({
              address: '',
              number: '',
              district: '',
              zipCode: '',
              complement: '',
              addressWithoutNumber: false,
              addressWithoutComplement: false,
              name: '',
              city: '',
              state: '',
            });
            onClose();
          },
        }
      );
    } else {
      addAddress.mutate(
        {
          address: {
            ...values,
            entityType: 'COMPANY',
            country: 'Brasil',
            entityId: selectedCompany?.id ?? '',
            state: values.state,
          },
        },
        {
          onSuccess: () => {
            reset({
              address: '',
              number: '',
              district: '',
              zipCode: '',
              complement: '',
              name: '',
              addressWithoutNumber: false,
              addressWithoutComplement: false,
              city: '',
              state: '',
            });
            onClose();
          },
        }
      );
    }
  }

  const modalTitle = isEdit
    ? isTaxAddress
      ? 'Editar endereço fiscal'
      : 'Editar endereço da empresa'
    : 'Novo endereço da empresa';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="4xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(handleSave)}>
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4}>
              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <FormControl isInvalid={!!errors.name}>
                    <FormLabel fontSize="sm">
                      Que nome você quer dar a esse endereço?
                    </FormLabel>
                    <Input {...register('name')} isDisabled={isTaxAddress} />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="name" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isInvalid={!!errors.zipCode} position="relative">
                    <FormLabel fontSize="sm">CEP</FormLabel>
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field }) => (
                        <Input
                          ref={field.ref}
                          as={IMaskInput}
                          inputRef={field.ref}
                          type="text"
                          mask="00000-000"
                          unmask={true}
                          value={field.value}
                          onKeyUp={() => {
                            if (zipCode?.length === 8 && dirtyFields?.zipCode) {
                              setShouldQuery(true);
                            }
                          }}
                          onAccept={(value: string) => {
                            if (value.length < 8 && state) {
                              resetAddressForm();
                            }
                            setValue('zipCode', value, {
                              shouldDirty: true,
                            });
                          }}
                          paddingRight={isFetching ? '32px' : '16px'}
                          onBlur={() => {
                            trigger('zipCode');
                          }}
                        />
                      )}
                    />
                    {isFetching && (
                      <Spinner
                        size="sm"
                        centerProps={{
                          position: 'absolute',
                          top: '45px',
                          right: '16px',
                          margin: '0',
                        }}
                      />
                    )}
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="zipCode" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={9}>
                  <FormControl isInvalid={!!errors.address}>
                    <FormLabel fontSize="sm">Endereço</FormLabel>
                    <Input
                      type="text"
                      disabled={!isFetched}
                      {...register('address')}
                      maxLength={addressMaxLength.address}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl>
                    <FormLabel fontSize="sm">Número</FormLabel>
                    <Input
                      type="text"
                      {...register('number')}
                      isDisabled={addressWithoutNumber || !isFetched}
                      maxLength={addressMaxLength.number}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="number" />
                    </FormErrorMessage>
                  </FormControl>
                  <Checkbox
                    isChecked={addressWithoutNumber}
                    {...register('addressWithoutNumber')}
                    onChange={() => {
                      setAddressWithoutNumber(!addressWithoutNumber);
                      trigger(['number', 'addressWithoutNumber']);
                      setValue('number', '');
                    }}
                    marginTop={2}
                    isDisabled={!isFetched}
                  >
                    Sem número
                  </Checkbox>
                </GridItem>
                <GridItem colSpan={9}>
                  <FormControl>
                    <FormLabel fontSize="sm">Complemento</FormLabel>
                    <Input
                      type="text"
                      {...register('complement')}
                      isDisabled={addressWithoutComplement || !isFetched}
                      maxLength={addressMaxLength.complement}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="complement" />
                    </FormErrorMessage>
                  </FormControl>
                  <Checkbox
                    isChecked={addressWithoutComplement}
                    {...register('addressWithoutComplement')}
                    onChange={() => {
                      setAddressWithoutComplement(!addressWithoutComplement);
                      trigger(['complement', 'addressWithoutComplement']);
                      setValue('complement', '');
                    }}
                    marginTop={2}
                    isDisabled={!isFetched}
                  >
                    Sem complemento
                  </Checkbox>
                </GridItem>
              </Grid>

              <Flex gap={4}>
                <FormControl isInvalid={!!errors.district}>
                  <FormLabel fontSize="sm">Bairro/Distrito</FormLabel>
                  <Input
                    type="text"
                    disabled={!isFetched}
                    {...register('district')}
                    maxLength={addressMaxLength.district}
                  />
                  <FormErrorMessage>
                    <ErrorMessage errors={errors} name="district" />
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.city}>
                  <FormLabel fontSize="sm">Cidade</FormLabel>
                  <Input
                    type="text"
                    disabled={!isFetched || !isErrorConsult}
                    {...register('city')}
                    maxLength={addressMaxLength.city}
                  />
                  <FormErrorMessage>
                    <ErrorMessage errors={errors} name="city" />
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.state}>
                  <FormLabel fontSize="sm">Estado</FormLabel>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field }) => {
                      return (
                        <CustomSelect
                          isDisabled={!isFetched || !isErrorConsult}
                          options={UFs}
                          value={UFs.find((uf) => uf.value === field.value)}
                          onChange={(value) => {
                            field.onChange(value?.value);
                          }}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    <ErrorMessage errors={errors} name="state" />
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
            <Text marginTop={6} color="brand.aux08">
              O endereço fiscal da empresa fica{' '}
              <Semibold>
                registrado nas cobranças (Boleto/Pix) e nas notas fiscais
              </Semibold>{' '}
              das recargas criadas
            </Text>
          </ModalBody>

          <ModalFooter marginTop={2}>
            <Button
              variant="outline"
              width="160px"
              mr={3}
              onClick={handleOnClickCancel}
            >
              Cancelar
            </Button>
            <Button
              width="160px"
              type="submit"
              isLoading={addAddress.isPending || editAddressData.isPending}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddAndEditAddressModal;
