import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useGetCompanyList, useSwitchCompanyMutation } from '../../api/company';
import Select from '../Select';
import { createFilter, SingleValue } from 'react-select';
import { Company } from '../../types/company';
import { Flex, Text } from '@chakra-ui/react';
import { formatCNPJ } from '../../utils/formatters/formatCNPJ';

const OptionLabel = ({ company }: { company: Company }) => (
  <Flex gap={2} paddingY={2}>
    <Text
      fontSize="14px"
      color="gray.700"
      lineHeight={1.3}
      whiteSpace="nowrap"
      width="155px"
    >
      ({formatCNPJ(company.registrationNumber)})
    </Text>
    <Text fontSize="16px" lineHeight={1.3} width="100%" flex="1">
      {company.tradeName}
    </Text>
  </Flex>
);

const CompanySelect = () => {
  const companyList = useGetCompanyList();
  const switchCompanyMutation = useSwitchCompanyMutation();
  const { selectedCompany } = useUserCompaniesStore();
  const { data, isPending } = companyList;
  const userCompanies = data?.companies ?? [];

  const options = userCompanies
    .sort((a, b) => a.tradeName.localeCompare(b.tradeName))
    .map((company) => ({
      ...company,
      label: <OptionLabel company={company} />,
    }));

  const defaultValue = selectedCompany && {
    ...selectedCompany,
    label: <OptionLabel company={selectedCompany} />,
  };

  function handleChange(company: SingleValue<Company>) {
    if (!company) {
      return;
    }
    switchCompanyMutation.mutate(company);
  }

  const customFilter = createFilter({ ignoreAccents: true });
  const filterOption = (
    option: {
      label: string;
      value: string;
      data: Company;
    },
    input: string
  ) => {
    const searchTerm = `${option.data.registrationNumber} ${formatCNPJ(option.data.registrationNumber)} ${option.data.tradeName}`;
    return customFilter(
      { label: searchTerm, value: searchTerm, data: option.data },
      input
    );
  };

  return (
    <Select
      isLoading={isPending}
      isDisabled={switchCompanyMutation.isPending}
      isSearchable={true}
      width={'400px'}
      options={options}
      defaultValue={defaultValue}
      getOptionValue={(company) => company.id}
      onChange={handleChange}
      filterOption={filterOption}
    />
  );
};

export default CompanySelect;
